<template>
  <div class="body fixed mobile">
    <!-- class: fixed, mobile -->
    <!-- Header -->
    <section class="header index">
      <a class="left" @click.prevent="$router.push('/record')">
        <img class="logo" src="@/assets/img/favicon.svg" />
        <span class="primary">{{ displayUserPointBalance }}</span>
      </a>
      <div class="uide-page-name">會員服務</div>
      <div class="right">
        <!-- <a class="icon iconButton" @click.prevent="barcodePopupOpen = true"> -->
        <a class="icon iconButton" @click.prevent="$router.push('/barcode')">
          <!-- <font-awesome-icon icon="fa-solid fa-barcode" size="xl"></font-awesome-icon> -->
          <img src="@/assets/img/barcode.svg" />
        </a>
        <!-- <NotificationBell
          v-if="triggerCountUnread"
          @count-unread="countUnread = $event"
        /> -->
      </div>
    </section>
    <!-- Main -->
    <section class="main profile main-no-x-padding">
      <!-- 個人資訊 -->
      <div class="main-x-padding">
        <div class="uide-profile-member mt-2">
          <div class="flex-7 flex flex-row">
            <div class="flex-2 flex items-center">
              <img
                width="40px"
                v-if="displayUserImage()"
                :src="displayUserImage()"
              />
              <img height="40px" class="circle" v-else src="@/assets/img/avatar_yunlin.jpg" />
            </div>
            <div class="flex-8">
              <div class="cNeutral900 font-medium text-lg">
                {{ displayName() }}
              </div>
              <div class="cNeutral500 text-base">數位縣民 {{ memberId }}</div>
            </div>
          </div>

          <div class="flex-3 flex items-center gap-4 justify-right">
            <a @click.prevent="$router.push('/profile/edit')">
              <img width="24px" src="@/assets/icon/uide/edit.svg" />
            </a>
            <a @click.prevent="$router.push('/barcode')">
              <img width="24px" src="@/assets/icon/uide/space.svg" />
            </a>
          </div>
        </div>

        <!-- 功能 -->
        <!-- <div v-if="enableStamp" class="functions flexH width">
          <a class="flexV center width" @click.prevent="$router.push('/record')">
            <img src="@/assets/icon/record.svg" />
            <span class="fM">{{ $t('交易紀錄') }}</span>          
          </a>
          <a class="flexV center width" @click.prevent="$router.push('/tickets')">
            <img src="@/assets/icon/ticket.svg" />
            <span class="fM">{{ $t('我的票券') }}</span>          
          </a>
          <a
            class="flexV center width"
            @click.prevent="$router.push('/stamp-cards')"
          >
            <img src="@/assets/icon/stamp.svg" />
            <span class="fM">{{ $t('我的集章') }}</span>          
          </a>
        </div> -->
        <a
          class="uide-profile-invite-btn mt-4"
          v-show="displayReferralCode()"
          @click.prevent="openReferralPopup"
        >
          <div class="flex flex-row gap-1">
            <span class="text-white text-sm"
              >推薦好友註冊雲林幣成功，送金幣</span
            ><img width="20px" src="@/assets/icon/uide/coin-gold.svg" />
          </div>

          <img width="24px" src="@/assets/icon/uide/chevron.svg" />
        </a>
      </div>

      <!-- <a
        v-if="!enableStamp"
        class="point flexH between end"
        @click.prevent="$router.push('/record')"
      >
        <div class="flexH">
          <div class="img">
            <img src="@/assets/icon/point.svg" />
          </div>
          <div class="flexV">
            <span class="fM">雲林幣扭一下</span>
            <span class="fXL primary"
              >{{ displayUserPointBalance
              }}<span class="fM primary">點</span></span
            >
          </div>
        </div>
        <div class="flexV" v-if="displayDate">
          <span class="fS">點數到期日</span>
          <span class="fS">{{ displayDate }}</span>
        </div>
      </a> -->

      <div class="uide-profile-board mt-4">
        <div class="text-c03 font-medium text-base">雲林金幣</div>
        <div class="flex flex-row items-center mb-4">
          <div class="flex gap-1 flex-1 justify-center" @click="$router.push('/coin-rule')">
            <div class="text-center">
              <img width="48" src="@/assets/icon/uide/profile-01.svg" />
              <div class="cNeutral900 text-base">金幣規則</div>
            </div>
          </div>
          <div
            @click.prevent="$router.push('/record')"
            class="flex gap-1 flex-1 justify-center"
          >
            <div class="text-center">
              <img width="48" src="@/assets/icon/uide/profile-02.svg" />
              <div class="cNeutral900 text-base">交易紀錄</div>
            </div>
          </div>
          <RouterLink to="/send" class="flex gap-1 flex-1 justify-center">
            <div class="text-center">
              <img width="48" src="@/assets/icon/uide/profile-03.svg" />
              <div class="cNeutral900 text-base">轉贈金幣</div>
            </div>
          </RouterLink>
        </div>

        <div v-if="storeAdminUrl" class="text-c03 font-medium text-base">特約商店申請及管理</div>
        <div v-if="storeAdminUrl" class="flex flex-row items-center mb-4">
          <!-- <a
            class="flex gap-1 flex-1 justify-center"
          >
            <div class="text-center">
              <img width="48" src="@/assets/icon/uide/profile-04.svg" />
              <div class="cNeutral900 disabled">特店申請</div>
            </div>
          </a> -->
          <a
            class="flex gap-1 flex-1 justify-center"
            @click.prevent="linkClickHandler(storeAdminUrl)"
          >
            <div class="text-center">
              <img width="48" src="@/assets/icon/uide/profile-05.svg" />
              <div class="cNeutral900">特店管理後台</div>
            </div>
          </a>
          <div class="flex gap-1 flex-1 justify-center"></div>
          <div class="flex gap-1 flex-1 justify-center"></div>
        </div>

        <div class="text-c03 font-medium text-base">系統相關</div>
        <div class="flex flex-row items-center mb-4">
          <router-link
            to="/customer-service"
            class="flex gap-1 flex-1 justify-center"
          >
            <div class="text-center">
              <img width="48" src="@/assets/icon/uide/profile-06.svg" />
              <div class="cNeutral900 text-base">聯絡客服</div>
            </div>
          </router-link>
          <a href="/setting" class="flex gap-1 flex-1 justify-center">
            <div class="text-center">
              <img width="48" src="@/assets/icon/uide/profile-07.svg" />
              <div class="cNeutral900 text-base">設定</div>
            </div>
          </a>
          <div class="flex gap-1 flex-1 justify-center" @click="$router.push('/e11')">
            <div class="text-center">
              <img width="48" src="@/assets/icon/uide/profile-08.svg" />
              <div class="cNeutral900 text-base">文字大小</div>
            </div>
          </div>
          <!-- <div class="flex gap-1 flex-1 justify-center" @click="logout">
            <div class="text-center">
              <div class="p-1">
                <font-awesome-icon :icon="['fas', 'right-from-bracket']" size="2x" color="#6B2DF5" />
              </div>
              <div class="cNeutral900 text-base">登出</div>
            </div>
          </div> -->
        </div>
      </div>
    </section>

    <!-- Popup (邀請碼) -->
    <ReferralPopup ref="referralPopupRef" />
    <!-- Popup (Code) -->
    <BarcodePopup
      v-if="barcodePopupOpen"
      @closePopup="barcodePopupOpen = false"
    />
    <section id="popLeave" class="popup">
      <div class="popBody">
          <a class="close" @click.prevent="closeLeaveModal"
              ><font-awesome-icon
                  icon="fas fa-times"
              ></font-awesome-icon
          ></a>
          <section class="popHeader">
              <!-- <span class="fL">Title</span> -->
          </section>
          <section class="popMain">
              <div class="fL text-center">提示</div>
              <div class="icon">
              </div>
              <span class="">您即將離開 App 前往外部連結，請問是否繼續？</span>
          </section>
          <section class="popFooter flex">
              <a
                  class="button bg-transparent text-black border border-primary flex-1  submit rounded"
                  @click.prevent="closeLeaveModal"
                  >返回</a
              >
              <a
                  class="button flex-1 submit rounded"
                  @click.prevent="goOut()"
                  ><span class="fM">確認</span></a
              >
          </section>
      </div>
  </section>
  </div>
</template>

<script>
import moment from "moment-timezone";
import { mapState, mapGetters } from "vuex";
// import NotificationBell from "@/components/NotificationBell.vue";
import BarcodePopup from "@/components/BarcodePopup.vue";
import ReferralPopup from '@/components/ReferralPopup.vue'

export default {
  name: "Profile",
  components: {
    // NotificationBell,
    BarcodePopup,
    ReferralPopup,
  },
  data() {
    return {
      apiHost: process.env.VUE_APP_API_HOST,
      merchantId: process.env.VUE_APP_MERCHANT_ID,
      appHost: process.env.VUE_APP_HOST + process.env.BASE_URL,
      storeAdminHost: process.env.VUE_APP_STORE_ADMIN_URL,
      myReferralCode: "",
      isNotificationBar: false,
      notificationBarContent: {
        title: "",
        message: "",
        time: "",
        points: "",
        reset: function () {
          this.title = "";
          this.message = "";
          this.time = "";
          this.points = "";
        },
      },
      firstNotification: {},
      userData: {},
      stampCards: [],
      tickets: [],
      barcodePopupOpen: false,
      nextUrl: '',
    };
  },
  // beforeRouteEnter(to, from, next) {
  //   // called before the route that renders this component is confirmed.
  //   // does NOT have access to `this` component instance,
  //   // because it has not been created yet when this guard is called!
  //   console.log(to, from);
  //   next((vm) => {
  //     let that = vm;
  //     console.log("user: ", that.user);
  //     if (that.user && that.user.userId) {
  //       vm.getUserPoints()
  //         .then((res) => {
  //           that.$store.commit("updateUserPoints", res);
  //         })
  //         .catch((err) => {
  //           console.log("err: ", err);
  //           next("/error");
  //         });
  //       vm.$store.commit(
  //         "updateUserInfo",
  //         Object.assign({}, vm.userInfo, {
  //           myReferralCode: vm.user.user.myReferralCode,
  //         })
  //       );
  //       vm.myReferralCode = vm.user.user.myReferralCode;
  //     } else {
  //       next("/error");
  //     }
  //   });
  // },
  created() {},
  mounted() {
    // this.getUserData();
    // this.getStampCards();
    // this.getTickets();
  },
  computed: {
    ...mapState(["user", "userInfo", "currentPath"]),
    ...mapGetters({
      userPoints: "getTyUserPoints",
    }),
    storeAdminUrl() {
      return process.env.VUE_APP_STORE_ADMIN_URL;
    },
    displayDate() {
      return this.lastestEndOfPoint
        ? this.tsToData(this.lastestEndOfPoint.endTs)
        : "";
      // return this.tsToData(
      //     this.userPoints &&
      //         this.userPoints.pointPeriods &&
      //         this.userPoints.pointPeriods.length > 0
      //         ? this.userPoints.pointPeriods[0].endTs
      //         : null
      // );
    },
    version() {
      return process.env.PACKAGE_VERSION;
    },
    displayUserPointBalance() {
      return this.userPoints && this.userPoints.pointBalance
        ? this.userPoints.pointBalance
        : 0;
    },
    lastestEndOfPoint() {
      if (
        this.userPoints &&
        this.userPoints.pointPeriods &&
        this.userPoints.pointPeriods.length > 0
      ) {
        let sorted = [...this.userPoints.pointPeriods];
        sorted.sort(function (a, b) {
          return a.endTs - b.endTs;
        });
        const latestEndOfPoint = sorted.find((p) => p.endTs >= moment().unix());
        return latestEndOfPoint;
      }
      return null;
    },
    numberOfStampCards() {
      return this.stampCards ? this.stampCards.length : 0;
    },
    numberOfTickets() {
      let ticketSum = 0;
      this.tickets.forEach((product) => {
        ticketSum += product.tickets.filter(
          (ticket) => ticket.status === "pickedup"
        ).length;
      });
      return ticketSum;
    },
    enableStamp() {
      return process.env.VUE_APP_ENABLE_STAMP === "true";
    },
    memberId() {
      return this.user.user._json.memberId
    }
  },
  methods: {
    goOut() {
      this.closeLeaveModal()
      window.open(this.nextUrl, '_blank');
    },
    linkClickHandler(url) {
      this.openLeaveModal();
      this.nextUrl = url
    },
    openLeaveModal() {
      const wrap = document.querySelector('#popLeave')
      wrap.style.display = 'flex'
    },
    closeLeaveModal() {
      const wrap = document.querySelector('#popLeave')
      wrap.style.display = 'none'
    },
    openReferralPopup() {
      this.$refs.referralPopupRef.openPop();
    },
    dummyUserData() {
      let result = {
        memberId: 47,
        uuid: null,
        oauthId: "U38a079aadef4a60ec5a6263112248b35",
        picture:
          "https://profile.line-scdn.net/0hNwmZpXdREVhQIzrAyWRuD2xmHzUnDRcQKEJdOHIlGmt1RFIHOEFXPSUiH2x4EFIHaBZebiErSW4p",
        idno: "H223292882",
        name: "Kay Lai",
        birthdate: "1988-03-05",
        phoneNumber: "0921185084",
        carrier: null,
        gender: 1,
        email: "clearsky0305@gmail.com",
        fullAddress: "桃園市平鎮區",
        isTyCitizen: 1,
        createdTs: 1647945287,
      };
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve({ data: result });
        }, 300);
      });
    },
    callUserDataAPI() {
      var config = {
        method: "get",
        url: `${this.apiHost}/identities/v1/merchants/${this.merchantId}/members/${this.user.userId}`,
        headers: {},
      };

      return this.$http(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
          return response;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getUserData() {
      this.callUserDataAPI()
        // this.dummyUserData()
        .then((res) => {
          this.userData = res.data;
        })
        .catch((err) => {
          console.log("err: ", err);
          this.$router.push("/error");
        });
    },
    displayReferralCode() {
      return this.user.user.myReferralCode;
    },
    displayName() {
      return this.userInfo.name;
    },
    displayUserImage() {
      return this.userInfo ? this.userInfo.picture : "";
    },
    tsToData(ts) {
      return moment(ts * 1000).format("YYYY-MM-DD");
    },
    getUserPoints() {
      var config = {
        method: "get",
        url: `${this.apiHost}/points/v1/merchants/${this.merchantId}/members/${this.user.userId}/points`,
        headers: {},
      };

      return this.$http(config)
        .then(function (response) {
          return response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    generateReferralCode() {
      return this.myReferralCode;
    },
    generateReferralUrl() {
      return encodeURIComponent(
        `${this.appHost}?referralCode=${this.myReferralCode}&openExternalBrowser=1`
      );
    },
    shareWithLINEUrl() {
      return `https://line.me/R/share?text=${this.generateReferralUrl()}`;
    },
    getPointHandler(event) {
      console.log("get points: ", event);
      this.updateUserPoints();
    },
    updateUserPoints() {
      this.getUserPoints()
        .then((res) => {
          console.log("update user points!");
          this.$store.commit("updateUserPoints", res);
        })
        .catch((err) => {
          console.log("err: ", err);
          this.router.push("/error");
        });
    },
    logout() {
      qcsso
        .logout({
          logoutRedirectUri: this.appHost.substring(0, this.appHost.length - 1),
        })
        .then(() => window.Yunlin?.postMessage(JSON.stringify({ type: "logout" })))
        .catch((err) => console.log(err)).finally(() => {
          sessionStorage.removeItem('hasSetLogin')
          console.log("JS Interface", { type: "logout" })
        })
    },
    callGetStampCardsAPI() {
      let config = {
        // url: `${this.apiHost}/stamps/v1/merchants/${this.merchantId}/collector/${this.user.userId}/stamp-cards`
        url: `${this.apiHost}/stamps/v1/merchants/${this.merchantId}/stamp-cards?uuid=${this.user.userId}`,
      };
      console.log("config: ", config);
      return this.$http(config);
    },
    getStampCards() {
      this.callGetStampCardsAPI()
        .then((res) => {
          this.stampCards = res.data;
          console.log("callGetStampCardsAPI: ", res.data);
        })
        .catch((err) => console.log(err));
    },
    callGetTicketsAPI() {
      let config = {
        url: `${this.apiHost}/tickets/v1/merchants/${this.merchantId}/members/${this.user.userId}/tickets`,
      };
      return this.$http(config);
    },
    getTickets() {
      this.callGetTicketsAPI()
        .then((res) => {
          this.tickets = res.data;
        })
        .catch((err) => console.log(err));
    },
  },
};
</script>

<style scoped>
.version {
  font-size: 0.5rem;
  color: #8080807a;
}
.circle {
  clip-path: circle(20px at center);
}
</style>
